<template>
  <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">

    <!-- GENERAL -->
    <vs-tab icon-pack="feather" icon="icon-file-text" :label="!isSmallerScreen ? 'ภาษีและใบเสร็จรับเงิน' : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <shop-receipt />
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-lock" :label="!isSmallerScreen ? 'ขยายวันเพื่อซัก' : ''">
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <shop-extending-date />
      </div>
    </vs-tab>

    <vs-tab icon-pack="feather" icon="icon-box" :label="!isSmallerScreen ? 'ช่องทางการรับสินค้า' : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">

      </div>
    </vs-tab>

    <vs-tab icon-pack="feather" icon="icon-tag" :label="!isSmallerScreen ? 'จัดการโปรโมชั่น' : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <promotion />
      </div>
    </vs-tab>

    <vs-tab icon-pack="feather" icon="icon-database" :label="!isSmallerScreen ? 'สะสมแต้ม' : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <collect-point />
      </div>
    </vs-tab>

    <vs-tab icon-pack="feather" icon="icon-message-square" :label="!isSmallerScreen ? 'รีวิวสินค้าหน้าร้าน' : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <review />
      </div>
    </vs-tab>

  </vs-tabs>
</template>

<script>
import ShopReceipt from "./ShopReceipt.vue"
import ShopExtendingDate from "./ShopExtendingDate.vue"
// import UserPassword from "./UserPassword.vue"
// import UserSettingsInfo from "./UserSettingsInfo.vue"
// import UserSettingsSocialLinks from "./UserSettingsSocialLinks.vue"
// import UserSettingsConnections from "./UserSettingsConnections.vue"
// import UserSettingsNotifications from "./UserSettingsNotifications.vue"

import Promotion from './Promotion.vue'
import CollectPoint from './CollectPoint.vue'
import Review from './Review.vue'

export default {
  components: {
    ShopReceipt,
    ShopExtendingDate,
    // UserPassword,
    // UserSettingsInfo,
    // UserSettingsSocialLinks,
    // UserSettingsConnections,
    // UserSettingsNotifications,
    Promotion,
    CollectPoint,
    Review
  },
  data() {
    return {

    }
  },
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    }
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
