<template>
  <vx-card v-if="$store.state.auth.currentPackage ==='m' || $store.state.auth.currentPackage ==='l'" style="min-height: 70vh" no-shadow>
    <!-- begin modal create -->
    <vs-popup
      class="custom-popup"
      :title="createModal.label"
      :active.sync="createModal.active"
    >
      <div class="px-8 mb-8">
        <vs-row>
          <vs-col class="flex items-center" vs-lg="5">
            <span>ชื่อโปรโมชั่น</span>
          </vs-col>
          <vs-col vs-lg="7">
            <vs-input
              class="w-full"
              v-model="modalDiscountItem.description"
              v-validate="'required'"
              name="description"
            ></vs-input>
            <span class="text-danger">
              {{ errors.first("description") }}
            </span>
          </vs-col>
        </vs-row>
        <vs-row class="mt-4">
          <vs-col class="flex items-center" vs-lg="5">
            <span>เปอร์เซ็นส่วนลด</span>
          </vs-col>
          <vs-col vs-lg="7">
            <vs-input
              type="number"
              v-model="modalDiscountItem.discount"
              v-validate="'required|min_value:1'"
              style="width: 132px !important"
              name="discount"
            ></vs-input>
            <span class="text-danger">
              {{ errors.first("discount") }}
            </span>
          </vs-col>
        </vs-row>
        <vs-row class="mt-4">
          <vs-col class="flex items-center" vs-lg="5">
            <span>ช่วงราคาลด "จาก-ถึง"</span>
          </vs-col>
          <vs-col vs-lg="7">
            <div class="flex">
              <div class="flex flex-col">
                <vs-input
                  type="number"
                  v-model="modalDiscountItem.startAmount"
                  v-validate="'required|min_value:1'"
                  name="startAmount"
                  class="w-full"
                ></vs-input>
                <span class="text-danger">
                  {{ errors.first("startAmount") }}
                </span>
              </div>
              <span class="flex items-center px-4" style="height: 38px">-</span>
              <div class="flex flex-col">
                <vs-input
                  type="number"
                  v-model="modalDiscountItem.untilAmount"
                  v-validate="'required|min_value:1'"
                  name="untilAmount"
                  class="w-full"
                ></vs-input>
                <span class="text-danger">
                  {{ errors.first("untilAmount") }}
                </span>
              </div>
            </div>
          </vs-col>
        </vs-row>
      </div>
      <vs-row vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="12"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-button
            @click="confirmDiscountModal"
            style="margin:10px;"
            color="success"
            type="filled"
          >
            ตกลง
          </vs-button>
          <vs-button
            @click="closeDiscountModal"
            style="margin:10px;"
            color="danger"
            type="filled"
          >
            ยกเลิก
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
    <!-- end modal create -->
    <!-- ส่วนลด -->
    <div
      v-if="tabActive == 1"
      class="grid-layout-container alignment-block "
      style="width:100%;"
    >
      <div class="mb-base">
        <div class="flex justify-between mb-4">
          <div class="flex">
            <div class="mr-4">
              <span
                :class="[
                  'px-2 py-1 cursor-pointer',
                  tabActive == 1 && 'bg-primary text-white'
                ]"
                :style="{ backgroundColor: tabActive == 2 && '#f2f2f2' }"
                @click="switchTab(1)"
              >
                ส่วนลด
              </span>
            </div>
            <div>
              <span
                :class="[
                  'px-2 py-1 cursor-pointer',
                  tabActive == 2 && 'bg-primary text-white'
                ]"
                :style="{ backgroundColor: tabActive == 1 && '#f2f2f2' }"
                @click="switchTab(2)"
              >
                แถม
              </span>
            </div>
          </div>
          <vs-button
            color="success"
            type="filled"
            style="width: 150px"
            @click="openCreateDiscount"
          >
            <font-awesome-icon icon="plus" class="cursor-pointer" />
            เพิ่มรหัส
          </vs-button>
        </div>
        <vs-table :data="discountItems" pagination max-items="20" search>
          <template slot="thead">
            <vs-th style="width: 1%">
              <div class="flex justify-center w-full">
                ลำดับ
              </div>
            </vs-th>
            <vs-th style="width: 1%">
              <div class="flex justify-center w-full">
                หัวข้อ
              </div>
            </vs-th>
            <vs-th style="width: 1%">
              <div
                class="flex justify-center w-full"
                style="white-space: nowrap !important"
              >
                เปอร์เซ็นส่วนลด
              </div>
            </vs-th>
            <vs-th style="width: 10%">
              <div
                class="flex justify-center w-full"
                style="white-space: nowrap !important"
              >
                ช่วงราคาลด "จาก-ถึง"
              </div>
            </vs-th>
            <vs-th style="width: 10%">
              <div class="flex justify-center w-full">
                อัพเดตล่าสุด
              </div>
            </vs-th>
            <!-- <vs-th style="width: 10%">
              <div class="flex justify-center w-full">
                อัพเดต
              </div>
            </vs-th> -->
            <vs-th style="width: 10%">
              <div class="flex justify-center w-full">
                สถานะ
              </div>
            </vs-th>
            <vs-th style="width: 10%">
              <div
                class="flex justify-center w-full"
                style="white-space: nowrap !important"
              >
                การจัดการ
              </div>
            </vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr
              :key="`discount-${index}-${tr.state}`"
              v-for="(tr, index) in data"
            >
            <vs-td
                style="white-space: nowrap !important"
              >
                {{ index + 1 }}
              </vs-td>
              <vs-td
                :data="data[index].description"
                style="white-space: nowrap !important"
              >
                {{ data[index].description }}
              </vs-td>
              <vs-td :data="data[index].discount">
                {{ data[index].discount }}
              </vs-td>
              <vs-td>
                {{ data[index].startAmount.toLocaleString() }}-{{
                  data[index].untilAmount.toLocaleString()
                }}
              </vs-td>
              <!-- <vs-td
                :data="data[index].updateDateTime"
                style="white-space: nowrap !important"
              >
                {{ $dateFormat(data[index].updateDateTime) }}

              </vs-td> -->
              <vs-td :data="data[index].updateBy">
                {{ $dateFormat(tr.updateDateTime) }}<br/>
                {{ data[index].updateBy }}
              </vs-td>
              <vs-td
                :data="data[index].state"
                :class="['flex justify-center', index == 0 && 'pt-8']"
              >
                <vs-switch
                  color="success"
                  v-model="tr.state"
                  @click="discountToggleState(tr.state, index)"
                >
                  <span slot="on">เปิด</span>
                  <span slot="off">ปิด</span>
                </vs-switch>
              </vs-td>
              <vs-td>
                <font-awesome-icon
                  icon="edit"
                  class="cursor-pointer mr-2"
                  size="lg"
                  style="color: #5A8EE6"
                  @click="openEditDiscount(tr)"
                />
                <font-awesome-icon
                  icon="trash"
                  class="text-danger cursor-pointer"
                  size="lg"
                  @click="removeDiscount(tr)"
                />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>
    <!-- แถม -->
    <div
      v-if="tabActive == 2"
      class="grid-layout-container alignment-block "
      style="width:100%;"
    >
      <div class="mb-base">
        <div class="flex justify-between mb-4">
          <div class="flex">
            <div class="mr-4">
              <span
                :class="[
                  'px-2 py-1 cursor-pointer',
                  tabActive == 1 && 'bg-primary text-white'
                ]"
                :style="{ backgroundColor: tabActive == 2 && '#f2f2f2' }"
                @click="switchTab(1)"
              >
                ส่วนลด
              </span>
            </div>
            <div>
              <span
                :class="[
                  'px-2 py-1 cursor-pointer',
                  tabActive == 2 && 'bg-primary text-white'
                ]"
                :style="{ backgroundColor: tabActive == 1 && '#f2f2f2' }"
                @click="switchTab(2)"
              >
                แถม
              </span>
            </div>
          </div>
          <vs-button
            color="success"
            type="filled"
            style="width: 150px"
            @click="saveFreeItem"
          >
            บันทึก
          </vs-button>
        </div>
        <vs-table :data="freeItems" search>
          <template slot="thead">
            <vs-th style="width: 10%">
              <div class="flex justify-center w-full">
                หัวข้อ
              </div>
            </vs-th>
            <!-- <vs-th style="width: 10%">
              <div class="flex justify-center w-full">
                โค้ด
              </div>
            </vs-th> -->
            <vs-th style="width: 10%">
              <div
                class="flex justify-center w-full"
                style="white-space: nowrap !important"
              >
                จำนวนเช่า
              </div>
            </vs-th>
            <vs-th style="width: 10%">
              <div
                class="flex justify-center w-full"
                style="white-space: nowrap !important"
              >
                จำนวนแถม
              </div>
            </vs-th>
            <vs-th style="width: 10%">
              <div class="flex justify-center w-full">
                สถานะ
              </div>
            </vs-th>
            <vs-th style="width: 10%">
              <div
                class="flex justify-center w-full"
                style="white-space: nowrap !important"
              >
                การจัดการ
              </div>
            </vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr
              :key="`free-${index}-${tr.state}`"
              v-for="(tr, index) in data"
            >
              <vs-td
                :data="data[index].description"
                style="white-space: nowrap !important"
              >
                <!-- {{ data[index].description }} -->
                <vs-input
                  type="text"
                  size="small"
                  v-model="data[index].description"
                  :name="`description_${index}`"
                  v-validate="'required'"
                />
                <span class="text-danger text-xs">{{
                  errors.first(`description_${index}`)
                }}</span>
              </vs-td>
              <!-- <vs-td
                :data="data[index].code"
                style="white-space: nowrap !important"
              >
                <vs-input
                  type="text"
                  size="small"
                  v-model="data[index].code"
                  :name="`code_${index}`"
                  v-validate="'required'"
                />
                <span class="text-danger text-xs">{{
                  errors.first(`code_${index}`)
                }}</span>
              </vs-td> -->
              <vs-td :data="data[index].rentQty">
                <!-- {{ data[index].rentQty }} -->
                <vs-input
                  type="number"
                  size="small"
                  v-model="data[index].rentQty"
                  style="width: 150px"
                  :name="`rentQty_${index}`"
                  v-validate="'required|min_value:1'"
                />
                <span class="text-danger text-xs">{{
                  errors.first(`rentQty_${index}`)
                }}</span>
              </vs-td>
              <vs-td :data="data[index].freeQty">
                <!-- {{ data[index].freeQty }} -->
                <vs-input
                  type="number"
                  size="small"
                  v-model="data[index].freeQty"
                  style="width: 150px"
                  :name="`freeQty_${index}`"
                  v-validate="'required|min_value:1'"
                />
                <span class="text-danger text-xs">{{
                  errors.first(`freeQty_${index}`)
                }}</span>
              </vs-td>
              <vs-td
                :data="data[index].state"
                :class="['flex justify-center', index == 0 && 'pt-8']"
              >
                <!-- <vs-switch
                  color="success"
                  v-model="tr.state"
                  @click="freeToggleState(tr.state, index)"
                > -->
                <vs-switch
                  color="success"
                  v-model="tr.state"
                  @change="toggleStatus(index)"
                >
                  <span slot="on">เปิด</span>
                  <span slot="off">ปิด</span>
                </vs-switch>
              </vs-td>
              <vs-td>
                <!-- <font-awesome-icon
                  icon="edit"
                  class="cursor-pointer mr-2"
                  size="lg"
                  style="color: #5A8EE6"
                /> -->
                <font-awesome-icon
                  icon="trash"
                  class="text-danger cursor-pointer"
                  size="lg"
                  @click="removeFreeItem(index)"
                />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div class="my-8">
          <vs-button color="primary" @click="addFreeItem"
            >เพิ่มรายการ</vs-button
          >
        </div>
        <vs-row>
          <vs-col vs-lg="2">
            <span
              class="text-danger flex items-center text-xl"
              style="height: 40px"
              >*ไม่รวม*</span
            >
          </vs-col>
          <vs-col vs-sm="12" vs-lg="4" class="px-2">
            <div class="flex mb-4">
              <span class="flex items-center mr-4 text-xl" style="height: 40px"
                >ประเภท</span
              >
              <multiselect
                v-model="excludeCategorieSelected"
                :options="productCategories"
                :multiple="true"
                :close-on-select="true"
                :hideSelected="true"
                :searchable="false"
                selectedLabel=""
                selectLabel=""
                deselectLabel=""
                placeholder=""
                open-direction="bottom"
                label="description"
                track-by="code"
              ></multiselect>
            </div>
          </vs-col>
          <vs-col vs-lg="1" class="px-2"></vs-col>
          <vs-col vs-sm="12" vs-lg="4" class="px-2">
            <div class="flex">
              <span
                class="flex items-center mr-4 text-xl"
                style="height: 40px; white-space: nowrap"
                >ชนิด</span
              >
              <multiselect
                v-model="excludeTextureSelected"
                :options="productTextures"
                :multiple="true"
                :close-on-select="true"
                :hideSelected="true"
                :searchable="false"
                selectedLabel=""
                selectLabel=""
                deselectLabel=""
                placeholder=""
                open-direction="bottom"
                label="description"
                track-by="code"
              ></multiselect>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </div>
  </vx-card>
  <vx-card class="flex justify-center items-center" v-else-if="$store.state.auth.currentPackage ==='s'" style="min-height: 70vh" no-shadow>
      <h1>สำหรับลูกค้า Package M ขึ้นไป</h1>
  </vx-card >
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  data() {
    return {
      tabActive: 1,
      createModal: {
        label: "เพิ่มข้อมูล",
        active: false,
        mode: "create" // create or edit
      },
      filterType: null,
      options: ["item1", "item2", "item3"],
      discountItems: [],
      freeItems: [],
      productCategories: [],
      productTextures: [],
      excludeCategorieSelected: [],
      excludeTextureSelected: [],
      modalDiscountItem: {
        code: "",
        startAmount: 0,
        untilAmount: 0,
        discount: 0,
        description: "",
        state: true
      },
      modalFreeItem: {
        freeItemSettingId: "",
        code: "",
        description: "",
        rentQty: 0,
        freeQty: 0,
        state: false,
        createDateTime: "",
        createBy: "",
        updateDateTime: "",
        updateBy: "",
        shopInfoId: ""
      }
    };
  },
  methods: {
    toggleStatus(toggleIndex) {
      let that = this;
      this.freeItems.forEach((user, index) => {
        that.freeItems[index].state = false;
      });
      this.freeItems[toggleIndex].state = true;
    },
    openCreateDiscount() {
      this.createModal = {
        label: "เพิ่มข้อมูล",
        active: true,
        mode: "create"
      };
    },
    openEditDiscount(tr) {
      this.createModal = {
        label: "แก้ไขข้อมูล",
        active: true,
        mode: "edit"
      };

      this.modalDiscountItem = tr;
    },
    closeDiscountModal() {
      this.clearModalDiscountItem();
    },
    addFreeItem() {
      this.freeItems.push({
        code: "",
        description: "new item",
        rentQty: 0,
        freeQty: 0,
        state: false
      });
    },
    removeDiscount(tr) {
      this.$swal({
        title: "ยืนยันการลบ",
        html: "",
        icon: "warning",
        showCancelButton: true
      }).then(confirm => {
        if (confirm.value) {
          this.confirmRemoveDiscount(tr);
        }
      });
    },
    removeFreeItem(index) {
      this.$swal({
        title: "ยืนยันการลบ",
        html: "",
        icon: "warning",
        showCancelButton: true
      }).then(confirm => {
        if (confirm.value) {
          this.confirmRemoveFreeItem(index);
        }
      });
    },
    confirmRemoveDiscount(tr) {
      const state = this.$store.state;
      const request = this.$customAxios(
        "POST",
        `${state.apiURL}/api/v2/DeleteDiscountSetting`,
        { discountSettingId: tr.discountSettingId },
        state.auth.apiHeader
      );

      request.then(res => {
        console.log(`>>> res delete discount items`);
        console.log(res);
        this.fetchDiscountItems();
      });
    },
    confirmRemoveFreeItem(index) {
      console.log(`>>> confirm remove free-item`, index);
      this.freeItems.splice(index, 1);
      this.updateFreeItem();
    },
    updateDiscount(discountItem) {
      console.log(`>>> update discount item`);
      const state = this.$store.state;
      const request = this.$customAxios(
        "POST",
        `${state.apiURL}/api/v2/UpdateDiscountSetting`,
        discountItem,
        state.auth.apiHeader
      );

      request.then(res => {
        console.log(`>>> res update discount`, res);
      });
    },
    async updateFreeItem(freeItem) {
      console.log(`>>> update free item`, freeItem);

       console.log(`>>> update this.excludeCategorieSelected`, this.excludeCategorieSelected);
      const state = this.$store.state;
      let categories = [];
      if( this.excludeCategorieSelected != null && this.excludeCategorieSelected.length > 0){
        this.excludeCategorieSelected.forEach(item => {
          categories.push(item.code);
        });
      }


        let textures = [];
        if(this.excludeTextureSelected != null && this.excludeTextureSelected.length > 0){
           this.excludeTextureSelected.forEach(item => {
          textures.push(item.code);
        });
        }

      const request = await this.$customAxios(
        "POST",
        `${state.apiURL}/api/v2/UpdateFreeItemSetting`,
        {
          freeItemSettings: this.freeItems,
          excludeCategories: categories,
          excludeTextures: textures
        },
        state.auth.apiHeader
      );

      console.log(`>>> res update free item`, request);



    },
    switchTab(val) {
      this.tabActive = val;
    },
    discountToggleState(currentState, toggleIndex) {
      this.discountItems[toggleIndex].state = !currentState;
      this.updateDiscount(this.discountItems[toggleIndex]);
    },
    freeToggleState(currentState, toggleIndex) {
      this.freeItems[toggleIndex].state = !currentState;
    },
    async fetchDiscountItems() {
      const state = this.$store.state;
      const request = await this.$customAxios(
        "POST",
        `${state.apiURL}/api/v2/GetDiscountList`,
        {},
        state.auth.apiHeader
      );


        this.discountItems = request;
    },

    clearModalDiscountItem() {
      this.modalDiscountItem = {
        code: "",
        startAmount: 0,
        untilAmount: 0,
        discount: 0,
        description: "",
        state: true
      };

      this.$validator.reset();
      this.createModal.active = false;
      this.fetchDiscountItems();
    },
    clearModalFreeItem() {
      this.modalFreeItem = {
        freeItemSettingId: "",
        code: "",
        description: "",
        rentQty: 0,
        freeQty: 0,
        state: false,
        createDateTime: "",
        createBy: "",
        updateDateTime: "",
        updateBy: "",
        shopInfoId: ""
      };
      this.fetchFreeItems();
    },
    confirmDiscountModal() {
      let that = this;
      if (this.createModal.mode === "create") {
        // create request
        console.log(`>>> create discount item`);
        this.$validator.validate().then(valid => {
          if (valid) {
            const state = this.$store.state;
            const request = this.$customAxios(
              "POST",
              `${state.apiURL}/api/v2/CreateDiscountSetting`,
              this.modalDiscountItem,
              state.auth.apiHeader
            );

            request.then(res => {
              console.log(`>>> res create discount`, res);
              that.clearModalDiscountItem();
            });
          }
        });
      } else {
        // edit request
        console.log(`>>> update discount item`);
        this.$validator.validate().then(valid => {
          if (valid) {
            const state = this.$store.state;
            const request = this.$customAxios(
              "POST",
              `${state.apiURL}/api/v2/UpdateDiscountSetting`,
              this.modalDiscountItem,
              state.auth.apiHeader
            );

            request.then(res => {
              console.log(`>>> res update discount`, res);
              that.clearModalDiscountItem();
            });
          }
        });
      }
    },
    saveFreeItem() {
      this.updateFreeItem();
      this.$swal({
        icon: "success",
        title: "บันทึกข้อมูลสำเร็จ"
      });
    },

    async fetchFreeItems() {
    // const state = this.$store.state;
    // const request = await this.$customAxios(
    //   "GET",
    //   `${state.apiURL}/api/v2/GetFreeItemSetting`,
    //   {},
    //   state.auth.apiHeader
    // );

     let request = null;

      try {
      request = await this.$http.get(
        this.$store.state.apiURL + "/api/v2/GetFreeItemSetting",
        this.$store.state.auth.apiHeader
      );

      console.log(`>>> res free items`);

      console.log(request.data.freeItemSettingViews);

      console.log("request.data.excludeCategories >>> ",request.data.excludeCategories);

      console.log("request.data.excludeTextures >>> ",request.data.excludeTextures);

      console.log("this.productCategories >>> ",this.productCategories);




      this.freeItems = request.data.freeItemSettingViews;
      console.log(`>>> request excludeCategories`,request.data.excludeCategories);


      let cates = [];
      if(request.data.excludeCategories != null && request.data.excludeCategories.length > 0){
         request.data.excludeCategories.forEach(item => {
           var code = this.productCategories.filter(x => x.code === item);
           console.log(`>>> this.productCategories `,this.productCategories);
           console.log(`>>> category `,code);
           if(code.length > 0){
              cates.push(code[0]);

           }
         });
      }

       let texts = [];
      if(request.data.excludeTextures != null && request.data.excludeTextures.length > 0){
         request.data.excludeTextures.forEach(item => {
           var code = this.productTextures.filter(x => x.code === item);
            console.log(`>>> this.productTextures `,this.productTextures);
           console.log(`>>> texture `,code);
           if(code.length > 0){
              texts.push(code[0]);

           }
         });
      }

      this.excludeCategorieSelected = cates;
      this.excludeTextureSelected =texts;


    } catch (error) {
      request = null;

    }





    },

    async fetchAllInfo() {
      // const state = this.$store.state;
      // const request = await  this.$customAxios(
      //   "POST",
      //   `${state.apiURL}/api/productgroup/all-info`,
      //   {},
      //   state.auth.apiHeader
      // );

      let request = null;

      try {
      request = await this.$http.post(
        this.$store.state.apiURL + "/api/productgroup/all-info",
        {},
        this.$store.state.auth.apiHeader
      );

    } catch (error) {
      request = null;

    }

       console.log(`>>> !@@@!! category `,request.data);

        this.productCategories = request.data.categories;
        this.productTextures = request.data.textures;


      //   let categories = [];
      //   request.data.categories.forEach(item => {
      //     categories.push(item);
      //   });

      // console.log(`>>> ***&&&& !@@@!! category `,categories);


      //   let textures = [];
      //   request.data.textures.forEach(item => {
      //     textures.push(item);
      //   });

      //    console.log(`>>> ***&&&& !@@@!! textures `,textures);



      //    let cates = [];
      //   if(request.excludeCategories != null && request.excludeCategories.length > 0){
      //      request.excludeCategories.forEach(item => {
      //        var code = this.productCategories.filter(x => x.code === item);
      //        console.log(`>>> this.productCategories `,this.productCategories);
      //        console.log(`>>> !@@@!! category `,code);
      //        if(code.length > 0){
      //           cates.push(code[0]);

      //        }
      //      });
      //   }

      //    let texts = [];
      //   if(request.excludeTextures != null && request.excludeTextures.length > 0){
      //      request.excludeTextures.forEach(item => {
      //        var code = this.productTextures.filter(x => x.code === item);
      //         console.log(`>>> this.productTextures `,this.productTextures);
      //        console.log(`>>> texture `,code);
      //        if(code.length > 0){
      //           texts.push(code[0]);

      //        }
      //      });
      //   }

      //   this.excludeCategorieSelected = cates;
      //   this.excludeTextureSelected =texts;



    }
  },
  async mounted() {
    await this.fetchDiscountItems();
    await this.fetchAllInfo();
    await this.fetchFreeItems();
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect--active {
  z-index: 9999 !important;
}

.multiselect__content-wrapper {
  z-index: 9999 !important;
}

.multiselect__content-wrapper .multiselect-leave-active{
  z-index: 99999 !important;
}
</style>
<style scoped>
tr td {
  text-align: center !important;
  vertical-align: middle !important;
  font-size: 12px;
}
</style>
