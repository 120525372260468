<template>
  <vx-card no-shadow>

    <div class = "grid-layout-container alignment-block " style = "width:100%;" >

    <div class="mb-base">
      <h4 class="mt-6 mb-4">จำนวนวันโดยเฉลี่ยสำหรับการซักชุด</h4>
      <div clss="px-4" style="font-size: 0.9rem;">
        จำนวนวันที่ขยายสำหรับการซักชุด กำหนดเพื่อสำรองวันสำหรับการซัก/ซ่อมชุด <br/>
        หลังจากที่ลูกค้าคืนชุดแล้ว เช่น หากกำหนด จำนวนวันที่สำหรับการซักชุดโดย<br/>
        ประมาณ เป็น 3 วัน กรณีลูกค้าเช่าชุดวันที่ 1 ถึง 5 ธันวาคม ระบบจะบวกวัน<br/>
        เผื่อการซักอีก 3 วันและทำการล็อคคิวชุดที่ลูกค้าเช่า ตั้งแต่วันที่ 1 ถึง 8 ธันวาคม <br/><br/>
        &nbsp;&nbsp;&nbsp;กรณีที่ไม่ต้องการกำหนดจำนวนวันเพื่อขยายเวลาการเช่าชุดให้กำหนดค่าเป็น 0

      </div>
    </div>


        <!-- Tax rate -->
        <vs-row vs-w="12">
          <vs-col  vs-lg="6" vs-sm="6" vs-xs="12" class="mt-4">
            <label class="text-md">จำนวนวันที่สำหรับการซักชุดโดยประมาณ (วัน)</label>
            <vs-input class="w-full"  type="number"  v-model="washingDate" />
          </vs-col>
        </vs-row>
      </div>


    <!-- Save & Reset Button -->
    <div class = "grid-layout-container alignment-block " style = "width:100%;" >
      <vs-row vs-w="12">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="12"  vs-sm="12" vs-xs="12" class="px-2" >
          <div class="flex flex-wrap items-center justify-end mt-base">
            <vs-button @click="update()" class="ml-4 mt-2">บันทึก</vs-button>
            <!-- <vs-button class="ml-4 mt-2" type="border" color="warning">Reset</vs-button> -->
          </div>
        </vs-col>
      </vs-row>
    </div>


  </vx-card>
</template>

<script>
import shapeFormat from "@/mixins/shapeFormat.js";
export default {
  mixins: [shapeFormat],
  data() {
    return {
      receiptHeader: "ใบเสร็จรับเงิน",
      inVat: false,
      vatSystem: false,
      washingDate: 3,
      answer: true,
      follow: false,
      news: false,
      product_update: false,
      blog: true,
      theInfo: {},

    }
  },
  methods: {
    async update(){

      var item = {"cleanFixDuration" : this.washingDate  } ;
      // var setting =null;
      try {
        await this.$http.post(
            this.$store.state.apiURL + "/api/shopinfo/change-clean-fix-duration",
            item,
            this.$store.state.auth.apiHeader
          );

          this.$vs.notify({
            title: "ดำเนินการสำเร็จ",
            position: "top-center",
            text: "เรียบร้อย",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success"
          });



    } catch (error) {
      // setting = null;

    }


    }
  },
  async created() {
    await this.$store.dispatch('shop/fetch');

    // Object.assign( this.theInfo,  this.$store.getters["shop/tax"]);
    this.receiptHeader = this.$store.getters["shop/tax"].receiptHeader;
    this.vatSystem = this.$store.getters["shop/tax"].vatSystem;
    this.inVat = this.$store.getters["shop/tax"].inVat;
    this.taxRate = this.$store.getters["shop/tax"].taxRate;

    var setting = null;
    try {
        setting = await this.$http.post(
            this.$store.state.apiURL + "/api/shopinfo/get-clean-fix-duration",
            {},
            this.$store.state.auth.apiHeader
          );

          // console.log("setting >>> ",setting.data);

          this.washingDate = setting.data.cleanFixDuration;

    } catch (error) {
      console.log(error);
      // setting = null;

    }
  },
  computed: {
    //  *** BEGIN : 2020-04-06 : TAX INFO
    // taxInfo(){
    //   return this.$store.getters["shop/tax"];
    // },

  },
  mounted() {
//     console.log('MOUNTED  this.taxInfo ',this.taxInfo);
// this.$nextTick(() => {
//        Object.assign(this.theInfo ,this.taxInfo)

// });

  },
  watch:{

    washingDate(value){
      if(value > 100){
         this.washingDate = 100;
      }
      if(value < 0){
         this.washingDate = 0;
      }
    }
  }
}
</script>
