<template>
  <vs-card v-if="$store.state.auth.currentPackage ==='l'" class="px-8 py-8">
    <h1>ลูกค้าเช็คสินค้าหน้าร้าน</h1>
    <div v-for="(item, index) in items" :key="`qrcode-${item.branchId}`" class="mt-8">
      <h3>{{ item.branchName }}</h3>
      <div class="px-4 py-4">
        <QRCanvas :options="{ data: fullQRCode(item.qrCodeText), cellSize: 4 }" />
      </div>
      <vs-button @click="changeQRCode(index, item.branchId)">
        <font-awesome-icon icon="sync-alt" />
        เปลี่ยน QR Code
      </vs-button>
    </div>
    <!-- generate qrcode -->
    <!-- <div class="mt-8">
      <h3>สาขา {{ nextCodeId }}</h3>
      <vs-button class="my-4" @click="generateQRCode">
        <font-awesome-icon icon="sync-alt" />
        สร้าง QR Code
      </vs-button>
    </div> -->
  </vs-card>

  <vx-card class="flex items-center justify-center" v-else-if="$store.state.auth.currentPackage ==='s' || $store.state.auth.currentPackage ==='m'" style="min-height: 70vh" no-shadow>
      <h1>ลูกค้าของท่านสามารถดูสินค้าผ่านมือถือได้ </h1>
      <h2>กำลังมาในอนาคต</h2>
  </vx-card >
</template>

<script>
import { QRCanvas } from "qrcanvas-vue";

export default {
  components: {
    QRCanvas
  },
  data() {
    return {
      items: [],
      nextCodeId: null,
    };
  },
  methods: {
    fullQRCode(qtText) {
      const state = this.$store.state;
      var fullQr =  `${state.apiURL}/mreview/${qtText}`;
      return fullQr;
    },
    async generateQRCode() {
      console.log(`>>> generate qrcode`)
      const state = this.$store.state;
      const res = await this.$customAxios(
        "POST",
        `${state.apiURL}/api/v2/GenBranchQRCode`,
        {
          branchId: state.auth.currentBranch.branchId
        },
        state.auth.apiHeader
      );

      // request.then(res => {
        console.log(`>>> res generate branch qrcode`, res)
        await this.fetchQRCode()
      // });
    },
    async changeQRCode(index, branchId) {
      console.log(`>>> refresh qrcode branch`, branchId);
      const state = this.$store.state;
      const res = await this.$customAxios(
        "POST",
        `${state.apiURL}/api/v2/RefreshBranchQRCode`,
        {
          branchId: branchId
        },
        state.auth.apiHeader
      );

      // request.then(res => {
        console.log(`>>> res RefreshBranchQRCode`, res)
        await this.fetchQRCode();
      // });
    },
    async fetchQRCode() {
      console.log(`>>> call fetchQRCode`)
      const state = this.$store.state;
      const res = await this.$customAxios(
        "GET",
        `${state.apiURL}/api/v2/GetAllQRCodes`,
        {},
        state.auth.apiHeader
      );

      // request.then(res => {
        console.log(`>>> res fetchQRCode`, res)
        let codeId = []
        res.forEach((item) => {
          codeId.push(item.branchName)
        })
        this.items = res
        this.nextCodeId = Math.max(...codeId) + 1
      // });
    }
  },
  async mounted() {
    console.log(`>>> review mounted`)
    await this.fetchQRCode();
  },
};
</script>

<style></style>
