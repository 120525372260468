<template>
  <vx-card no-shadow>

    <div class = "grid-layout-container alignment-block " style = "width:100%;" >

    <div class="mb-base">
      <h4 class="mt-6 mb-4">ข้อมูลใบเสร็จรับเงิน</h4>


      <div class="flex items-center mb-4">
        <!-- Tax rate -->
        <vs-row vs-w="12">
          <vs-col  vs-lg="6" vs-sm="6" vs-xs="12" class="mt-4">
            <label >หัวใบเสร็จ</label>
            <vs-input class="w-full"   v-model="receiptHeader" />
          </vs-col>
        </vs-row>
      </div>

    </div>


      <h4 class="mt-6 mb-4">ข้อมูลภาษีมูลค่าเพิ่ม</h4>
       <vs-row vs-w="12">
         <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="12"  vs-sm="12" vs-xs="12" class="px-2" >
            <div class="flex items-center mb-4">
              <vs-switch v-model="vatSystem" >
               <span slot="on">ใช่</span>
                <span slot="off">ไม่ใช่</span>
               </vs-switch>
              <span class="ml-4">จดภาษีมูลค่าเพิ่มแล้ว</span>
            </div>
         </vs-col>
      </vs-row>
       <vs-row vs-w="12">
         <vs-col v-if="vatSystem" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="12"  vs-sm="12" vs-xs="12" class="px-2" >
            <div class="flex items-center mb-4">
              <vs-switch v-model="inVat" >
                <span slot="on">ใช่</span>
                <span slot="off">ไม่ใช่</span>
              </vs-switch>
              <span class="ml-4">ราคาเช่าในสต็อคเป็นราคาเช่าที่รวมภาษีมูลค่าเพิ่มแล้ว</span>
            </div>
         </vs-col>
      </vs-row>
        <!-- Tax rate -->
        <vs-row vs-w="12">
          <vs-col v-if="vatSystem"  vs-lg="6" vs-sm="6" vs-xs="12" class="mt-4">
            <label class="text-md">อัตรภาษี (หน่วยเป็น %)</label>
            <vs-input class="w-full"  type="number"  v-model="taxRate" />
          </vs-col>
        </vs-row>
      </div>


    <!-- Save & Reset Button -->
    <div class = "grid-layout-container alignment-block " style = "width:100%;" >
      <vs-row vs-w="12">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="12"  vs-sm="12" vs-xs="12" class="px-2" >
          <div class="flex flex-wrap items-center justify-end mt-base">
            <vs-button @click="update()" class="ml-4 mt-2">บันทึก</vs-button>
            <!-- <vs-button class="ml-4 mt-2" type="border" color="warning">Reset</vs-button> -->
          </div>
        </vs-col>
      </vs-row>
    </div>


  </vx-card>
</template>

<script>
import shapeFormat from "@/mixins/shapeFormat.js";
export default {
  mixins: [shapeFormat],
  data() {
    return {
      receiptHeader: "ใบเสร็จรับเงิน",
      inVat: false,
      vatSystem: false,
      taxRate: 7,
      answer: true,
      follow: false,
      news: false,
      product_update: false,
      blog: true,
      theInfo: {},

    }
  },
  methods: {
    async update(){
      var item = {receiptHeader : this.receiptHeader, inVat : this.inVat, vatSystem : this.vatSystem , taxRate : this.taxRate  } ;
      try{
        await this.$store.dispatch('shop/updateShopSetting' , item);
        this.noticeSuccess("บันทึกสำเร็จ")
      }
      catch(err){
        console.log('');

      }


    }
  },
  async created() {
    await this.$store.dispatch('shop/fetch');

    // Object.assign( this.theInfo,  this.$store.getters["shop/tax"]);
    this.receiptHeader = this.$store.getters["shop/tax"].receiptHeader;
    this.vatSystem = this.$store.getters["shop/tax"].vatSystem;
    this.inVat = this.$store.getters["shop/tax"].inVat;
    this.taxRate = this.$store.getters["shop/tax"].taxRate;
  },
  computed: {
    //  *** BEGIN : 2020-04-06 : TAX INFO
    // taxInfo(){
    //   return this.$store.getters["shop/tax"];
    // },

  },
  mounted() {
//     console.log('MOUNTED  this.taxInfo ',this.taxInfo);
// this.$nextTick(() => {
//        Object.assign(this.theInfo ,this.taxInfo)

// });

  },
  watch:{
    // taxInfo(){
    //    Object.assign(this.theInfo ,this.taxInfo);
    //    console.log('WATCHED  this.theInfo ',this.theInfo);
    // },
    vatSystem(value){
      if(value === false)
        this.inVat = false
    },
    taxRate(value){
      if(value > 100){
         this.taxRate = 100;
      }
      if(value < 0){
         this.taxRate = 0;
      }
    }
  }
}
</script>
