<template>
  <vx-card v-if="$store.state.auth.currentPackage ==='m' || $store.state.auth.currentPackage ==='l'" no-shadow>
    <div class="grid-layout-container alignment-block " style="width:100%;">
      <div class="mb-base">
        <div class="flex justify-end mb-4">
          <vs-button
            color="success"
            type="filled"
            style="width: 150px"
            @click="saveCollectPoint"
          >
            บันทึก
          </vs-button>
        </div>
        <vs-table :data="collectPoints">
          <template slot="thead">
            <vs-th class="bg-primary text-white" style="width: 1%">
              <div class="flex justify-center w-full">
                ยอดซื้อ
              </div>
            </vs-th>
            <vs-th class="bg-primary text-white" style="width: 1%">
              <div class="flex justify-center w-full">
                แต้มสะสม
              </div>
            </vs-th>
            <vs-th class="bg-primary text-white" style="width: 10%">
              <div class="flex justify-center w-full">
                อัพเดตล่าสุด
              </div>
            </vs-th>
            <vs-th class="bg-primary text-white" style="width: 10%">
              <div class="flex justify-center w-full">
                อัพเดตโดย
              </div>
            </vs-th>
            <vs-th class="bg-primary text-white" style="width: 10%">
              <div class="flex justify-center w-full">
                สถานะ
              </div>
            </vs-th>
            <vs-th class="bg-primary text-white" style="width: 10%">
              <div class="flex justify-center w-full">
                การจัดการ
              </div>
            </vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :key="index" v-for="(tr, index) in data">
              <vs-td :data="data[index].buy" :class="[index == 0 && 'pt-8']">
                <vs-input
                  type="number"
                  v-validate="'required|min_value:1'"
                  v-model="data[index].buy"
                  size="small"
                  style="width: 150px"
                  :name="`buy_${index}`"
                />
                <span class="text-danger text-xs">{{
                  errors.first(`buy_${index}`)
                }}</span>
              </vs-td>
              <vs-td :data="data[index].point" :class="[index == 0 && 'pt-8']">
                <vs-input
                  type="number"
                  v-validate="'required|min_value:1'"
                  v-model="data[index].point"
                  size="small"
                  style="width: 150px"
                  :name="`point_${index}`"
                />
                <span class="text-danger text-xs">{{
                  errors.first(`point_${index}`)
                }}</span>
              </vs-td>
              <vs-td
                :data="data[index].updateDateTime"
                :class="[index == 0 && 'pt-8']"
                style="white-space: nowrap !important"
              >
                {{ data[index].updateDateTime }}
              </vs-td>
              <vs-td
                :data="data[index].updateBy"
                :class="[index == 0 && 'pt-8']"
              >
                {{ data[index].updateBy }}
              </vs-td>
              <vs-td
                :data="data[index].active"
                :class="['flex justify-center', index == 0 && 'pt-8']"
              >
                <vs-switch
                  color="success"
                  v-model="tr.active"
                  @change="toggleStatus(index)"
                >
                  <span slot="on">เปิด</span>
                  <span slot="off">ปิด</span>
                </vs-switch>
              </vs-td>
              <vs-td :class="[index == 0 && 'pt-8']">
                <font-awesome-icon
                  icon="trash"
                  class="text-danger cursor-pointer"
                  size="lg"
                  @click="removeCollectPoint(tr)"
                />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div class="mt-8">
          <vs-button color="primary" type="filled" @click="addCollectPoint">
            เพิ่มรายการ
          </vs-button>
        </div>
      </div>
    </div>
  </vx-card>

   <vx-card class="flex justify-center items-center" v-else-if="$store.state.auth.currentPackage ==='s'" style="min-height: 70vh" no-shadow>
      <h1>สำหรับลูกค้า Package M ขึ้นไป</h1>
  </vx-card >
</template>

<script>
export default {
  computed: {
    // PROFILE
    user_displayName() {
      // return JSON.parse(localStorage.getItem('userInfo')).displayName
      return JSON.parse(localStorage.getItem("username")) || "Guest";
    }
  },
  data() {
    return {
      users: [
        {
          id: 1,
          total: {
            value: 100,
            valid: true
          },
          point: {
            value: 1,
            valid: true
          },
          lastUpdate: "2021-01-07",
          updateBy: "support@alexrental.app",
          status: false,
          validData: true
        },
        {
          id: 2,
          total: {
            value: 200,
            valid: true
          },
          point: {
            value: 2,
            valid: true
          },
          lastUpdate: "2021-01-07",
          updateBy: "support@alexrental.app",
          status: false,
          validData: true
        }
      ],
      collectPoints: []
    };
  },
  methods: {
    toggleStatus(toggleIndex) {
      let that = this;
      this.collectPoints.forEach((user, index) => {
        that.collectPoints[index].active = false;
      });
      this.collectPoints[toggleIndex].active = true;
    },
    addCollectPoint() {
      console.log(`>>> add field collect point`);
      console.log(this.$store.state);
      const currentDateTime = this.$dateFormat(new Date().toISOString());
      this.collectPoints.push({
        active: false,
        buy: 0,
        point: 0,
        createBy: this.user_displayName,
        updateBy: this.user_displayName,
        createDateTime: currentDateTime,
        updateDateTime: currentDateTime
      });
    },
    removeCollectPoint(tr) {
      console.log(`>>> remove collect point`);

      this.$swal({
        title: "ยืนยันการลบ",
        html: "",
        icon: "warning",
        showCancelButton: true
      }).then(confirm => {
        if (confirm.value) {
          this.confirmRemoveCollectPoint(tr);
        }
      });
    },
    confirmRemoveCollectPoint(tr) {
      console.log(`>>> confirm remove collect point`);
      this.collectPoints = this.collectPoints.filter(
        item => item.pointSettingId !== tr.pointSettingId
      );
      this.updatePointSetting();
      this.$swal("ลบข้อมูลสำเร็จ", "", "success");
    },
    saveCollectPoint() {
      console.log(`>>> save collect point`);
      this.$validator.validate().then(valid => {
        if (valid) {
          this.updatePointSetting();
          this.$swal({
            icon: "success",
            title: "บันทึกข้อมูลสำเร็จ"
          });
        }
      });
    },
    fetchCollectPoint() {
      console.log(`>>> fetch collect point`);
      const state = this.$store.state;
      const request = this.$customAxios(
        "GET",
        `${state.apiURL}/api/v2/GetPointSetting`,
        {},
        state.auth.apiHeader
      );

      request.then(res => {
        res.forEach((item, index) => {
          res[index].updateDateTime = this.$dateFormat(item.updateDateTime);
        });
        this.collectPoints = res;
      });
    },
    updatePointSetting() {
      console.log(`>>> update collect point`);
      let that = this;
      const state = this.$store.state;
      const request = this.$customAxios(
        "POST",
        `${state.apiURL}/api/v2/SavePointSetting`,
        this.collectPoints,
        state.auth.apiHeader
      );

      request.then(res => {
        res.forEach((item, index) => {
          res[index].updateDateTime = that.$dateFormat(item.updateDateTime);
        });
        this.collectPoints = res;
      });
    }
  },
  mounted() {
    console.log(">>> collect point mounted");
    this.fetchCollectPoint();
  }
};
</script>

<style scoped>
tr td {
  text-align: center !important;
  vertical-align: middle !important;
}
</style>
